import Vue from 'vue';
import { LocalStorage } from './localStorage';
import * as Sentry from '@sentry/browser';
export class Data {
	objectName = 'Data';
	// static server = 'https://api.profielnorm.com/';
	static server = 'https://api-acc.profielnorm.com/';
	// static server = 'https://api-dev.profielnorm.com/';
	// static server = 'https://localhost:44370/';

	static api = 'api/';
	logError = false;
	localStorage = new LocalStorage();
	maxRetries = 60;
	retryOnError = false;

	static get baseUrl() {
		return Data.server + Data.api;
	}

	_list = {};
	listRequest = null;
	itemRequest = null;
	itemRequests = {};
	retryWatch = null;
	currentItemRetry = 0;
	itemRetry = false;
	store = null;
	lastRequest = {};
	_currentPage = 1;
	lastItemResult = null;

	get currentPage() {
		return this._currentPage;
	}
	set currentPage(value) {
		this._currentPage = value;
	}
	totalItems = 0;
	perPage = 25;
	queue = [];
	onListChanged(cacheId) {
		// in het leven geroepen omdat sommige lijsten gecached moeten worden en dan afhankelijk zijn van een _list[cacheId]
	}
	executeQueue() {
		if (this.queue.length > 0) {
			let queueItem = this.queue[0];
			this.list(
				queueItem.data,
				queueItem.force,
				queueItem.cacheId,
				queueItem.urlExtension,
				(result) => {
					if (typeof queueItem.thenCallBack === 'function') {
						queueItem.thenCallBack(result);
					}
					this.executeQueue();
				},
				(exception) => {
					if (this.logError === true) {
						Sentry.captureMessage('objectName: ' + this.objectName + ',data: ' + JSON.stringify(queueItem.data) + ', error: ' + exception.message);
					}
					if (typeof queueItem.catchCallBack === 'function') {
						queueItem.catchCallBack(exception);
					}

					this.executeQueue();
				},
			);
			this.queue.shift();
		}
	}
	isLoaded(cacheId) {
		// eerst check op variabelen en eventuele default-waarden
		if (typeof cacheId === 'undefined' || cacheId === null) {
			cacheId = 'list';
		}

		if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
			// nog geen lastreqest aanwezig dan ook niet geladen
			return false;
		}
		if (typeof this.lastRequest[cacheId].loaded === 'undefined' || this.lastRequest[cacheId].loaded === null) {
			// als property loaded niet aanwezig is object nog niet geladen
			return false;
		}
		return this.lastRequest[cacheId].loaded;
	}
	retryLoad(request) {
		request.retry = true;
		this.list(
			request.data,
			request.force,
			request.cacheId,
			request.urlExtension,
			(result) => {
				if (typeof request.thenCallBack === 'function') {
					request.thenCallBack(result);
				}
				request.callBacks.forEach((callBack) => {
					if (typeof callBack === 'function') {
						callBack(result);
					}
				});
			},
			(exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('objectName: ' + this.objectName + ',data: ' + JSON.stringify(request.data) + ', error: ' + exception.message);
				}
				if (typeof request.catchCallBack === 'function') {
					request.catchCallBack(exception);
				}
			},
		);
	}

	setStore(store) {
		this.store = store;
	}
	restoreFromLocalStorage() {
		this.localStorage.restore(this);
	}
	persistentData() {
		return {}; // default niets opslaan
	}
	saveToLocalStorage() {
		this.localStorage.save(this);
	}
	removeFromLocalStorage() {
		this.localStorage.remove(this);
	}
	fetch(json) {
		// doorloop object
		for (let key in json) {
			// als veld aanwezig in object zelf dan uit meegegeven resultaat de waarde halen
			if (typeof this[key] !== 'undefined') {
				this[key] = json[key];
			}
		}
	}
	sameRequest(data, cacheId) {
		if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
			return false;
		}

		let same = true;
		let lastRequest = this.lastRequest[cacheId].data;

		// doorloop object
		for (let key in data) {
			// controleer op zelfde velden
			if (lastRequest[key] !== data[key]) {
				same = false;
			}
		}
		for (let key in lastRequest) {
			// controleeer op zelfde velden
			if (lastRequest[key] !== data[key]) {
				same = false;
			}
		}
		return same;
	}
	listPaging(data, force) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof force === 'undefined' || force === null) {
			force = false;
		}

		// specifieke data voor paging zetten
		data.currentPage = this.currentPage;
		data.perPage = this.perPage;
		// de rest wordt in list afgehandeld
		return this.list(
			data,
			force,
			'paging',
			'/paging',
			(result) => {
				let newList = [];
				result.data.forEach((item) => {
					newList.push(this.fetchListItem(item));
				});

				this._list.paging = newList;
				this.currentPage = result.currentPage;
				this.totalItems = result.totalCount;
				this.perPage = result.perPage;
				this.listRequest = null;
			},
			(exception) => {
				this.listRequest = null;
			},
		);
	}
	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		return item;
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined' || this.store === null || typeof this.store === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// eerst check op variabelen en eventuele default-waarden
		if (typeof cacheId === 'undefined' || cacheId === null) {
			cacheId = 'list';
		}

		if (typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null && this.lastRequest[cacheId].retryWatch !== null && this.lastRequest[cacheId].retry !== true) {
			// als er al een keer een verzoek is geweest waarbij het fout is gegaan (retryWatch !== null) die wacht dan x tijd voor de volgende poging. Als het niet de volgende poging is (volgende poging krijgt retry=true)
			// dan negeren
			if (typeof thenCallBack === 'function') {
				if (this.lastRequest[cacheId].callBacks.indexOf(thenCallBack) === -1) {
					this.lastRequest[cacheId].callBacks.push(thenCallBack);
				}
			}

			return null;
		}

		// als nog niet bestaat de cacheId als list dan toevoegen zodat hij reactive is
		if (typeof this._list[cacheId] === 'undefined' || this._list[cacheId] === null) {
			Vue.set(this._list, cacheId, []);
		}
		// lijst alleen opnieuw ophalen als:
		// - nog niet is opgehaald (undefind en null)
		// - het geforceerd moet
		// - het request (de data) afwijkend is
		// - het request de vorige keer niet geladen kon worden (in de catch kwam)
		// hierdoor wordt niet bij ieder request de lijst opgehaald van de server maar staat hij gecached
		if (
			typeof this._list[cacheId] === 'undefined' ||
			this._list[cacheId] === null ||
			force === true ||
			this.sameRequest(data, cacheId) === false ||
			(typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null && this.lastRequest[cacheId].loaded === false)
		) {
			if (this.listRequest === null) {
				let url = Data.baseUrl + this.url;
				if (typeof urlExtension !== 'undefined' && urlExtension != null) {
					url += urlExtension;
				}
				// als data meegegeven

				if (typeof data !== 'undefined' && data != null) {
					// dataobject aan url toevoegen
					if (url.includes('?') === false) {
						url += '?';
					}
					let query = '';
					for (let key in data) {
						if (query !== '') {
							query += '&';
						}
						query += key + '=' + data[key];
					}
					if (query !== '') {
						url += query;
					}
				}

				// verstuur het verzoek om de lijst op te halen
				this.listRequest = this.store.dataAccess
					.get(url, data, null, null, this.objectName + (cacheId !== 'list' ? '.' + cacheId : ''))
					.then((result) => {
						if (typeof result.data !== 'undefined' && result.data !== null && typeof result.data.forEach === 'function') {
							//als data bestaat dan is het een paging lijst
							let newList = [];
							result.data.forEach((item) => {
								newList.push(this.fetchListItem(item));
							});
							Vue.set(this._list, cacheId, newList);
							this.setPagingItems(result, cacheId);
						} else {
							if (typeof result.forEach === 'function') {
								let newList = [];
								result.forEach((item) => {
									newList.push(this.fetchListItem(item));
								});
								Vue.set(this._list, cacheId, newList);
								this.onListChanged(cacheId);
							}
						}

						this.listRequest = null;

						if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
							this.lastRequest[cacheId] = { retries: 0, callBacks: [] };
						}
						// this.lastRequest[cacheId].result = result; // opslaan voor latere callbacks maar wordt nu niet gebruikt dus uitgezet
						this.lastRequest[cacheId].data = typeof data === 'undefined' || data === null ? {} : data;
						this.lastRequest[cacheId].cacheId = cacheId;
						this.lastRequest[cacheId].urlExtension = urlExtension;

						if (this.lastRequest[cacheId].retry !== true) {
							// als het de retry is dan bevat de callback ook de functie naar de retry. De oude is de juiste
							this.lastRequest[cacheId].thenCallBack = thenCallBack;
							this.lastRequest[cacheId].catchCallBack = catchCallBack;
						}
						this.lastRequest[cacheId].loaded = true;

						// retry hier resetten voor volgende geforceerde verzoek
						this.lastRequest[cacheId].retries = 0;
						this.lastRequest[cacheId].retry = false;
						this.lastRequest[cacheId].retryWatch = null;
						if (typeof thenCallBack === 'function') {
							thenCallBack(result);
						}
						return this._list[cacheId];
					})
					.catch((exception) => {
						if (this.logError === true) {
							Sentry.captureMessage(
								'url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + (cacheId !== 'list' ? '.' + cacheId : '') + ', error: ' + exception.message,
							);
						}
						// bij catch wordt het verzoek ook opgeslagen. Alleen loaded op false en het aantal retries bijgehouden
						if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
							this.lastRequest[cacheId] = { retries: 0, callBacks: [] };
						}
						if (this.store.mockupData.useMockupData === false && this.retryOnError === true) {
							// aleen opnieuw laden als geen mockupdata
							// hier nog kijken naar bij welke errors wel/niet. 404 heeft niet zoveel zin. Mogelijk alleen netwerkerrors

							// als er een retry wacht hier deze resetten
							clearTimeout(this.lastRequest[cacheId].retryWatch);
							this.lastRequest[cacheId].data = typeof data === 'undefined' || data === null ? {} : data;

							this.lastRequest[cacheId].urlExtension = urlExtension;
							this.lastRequest[cacheId].cacheId = cacheId;

							if (this.lastRequest[cacheId].retry !== true) {
								// als het de retry is dan bevat de callback ook de functie naar de retry. De oude is de juiste. Anders veroorzaakt dit een loop
								this.lastRequest[cacheId].thenCallBack = thenCallBack;
								this.lastRequest[cacheId].catchCallBack = catchCallBack;
							}

							this.lastRequest[cacheId].loaded = false;
							// retry wordt hier op false gezet en zodra de retry wordt afgetrapt na timeout wordt hij op true gezet
							this.lastRequest[cacheId].retry = false;
							this.lastRequest[cacheId].retries++;

							// als nog niet maximaal retries dan timeout en verzoek opnieuw aftrappen
							if (this.lastRequest[cacheId].retries < this.maxRetries) {
								this.lastRequest[cacheId].retryWatch = setTimeout(() => {
									this.retryLoad(this.lastRequest[cacheId]);
								}, 1000);
							}
						}

						this.listRequest = null;
						if (typeof catchCallBack === 'function') {
							catchCallBack(exception);
						}
						return this._list[cacheId];
					});
			} else {
				this.listRequest
					.then((result) => {
						if (typeof thenCallBack === 'function') {
							thenCallBack(result);
						}
					})
					.catch((error) => {
						if (this.logError === true) {
							Sentry.captureMessage(' objectName: ' + this.objectName + (cacheId !== 'list' ? '.' + cacheId : '') + ', data: ' + JSON.stringify(data) + ', error: ' + error.message);
						}
						if (typeof catchCallBack === 'function') {
							catchCallBack(error);
						}
					});
			}
		} // hier nog iets doen met de callbacks. Als je nu dit doet gaat het fout:  else if (typeof thenCallBack === 'function') thenCallBack(this._list[cacheId].result);

		// geef de lijst terug.
		return this._list[cacheId];
	}
	setPagingItems(result, cacheId) {
		this.currentPage = result.currentPage;
		this.totalItems = result.totalCount;
		this.perPage = result.perPage;
		this.listRequest = null;
	}
	getById(id, force, thenCallBack, catchCallBack) {
		// onthoud id
		this.lastId = id;
		// samenstellen url
		let url = Data.baseUrl + this.url + '/Item';
		if (typeof id !== 'undefined' && id !== null) {
			url += '?id=' + id;
		}
		if (id === this.lastId && force === false) {
			return;
		}
		return this.get(url, force, thenCallBack, catchCallBack);
	}
	get(url, force, thenCallBack, catchCallBack, cacheId) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// variabelen op defaultwaarde zetten
		if (typeof force === 'undefined' || force === null) {
			force = false;
		}
		if (typeof cacheId === 'undefined' || cacheId === null) {
			cacheId = 'item';
		}

		if (typeof cacheId !== 'undefined' && cacheId !== null) {
			if (typeof this.itemRequests[cacheId] === 'undefined') {
				this.itemRequests[cacheId] = null;
			}
		}

		// als er nog een verzoek loopt of als id de vorige keer ook opgehaald is en dit geen geforceerd verzoek is
		// voorkom steeds opnieuw opvragen. als id al is opgevraagd zijn gegevens correct
		if (this.itemRequests[cacheId] !== null && force === false) {
			return; // niet opvragen
		}
		// verstuur verzoek
		this.itemRequests[cacheId] = this.store.dataAccess
			.get(url, null, null, null, this.objectName + '.Item')
			.then((result) => {
				// resultaat ontvangen dan in de juiste velden plaatsen
				if (result === null) {
					this.id = -1;
				}
				this.lastItemResult = result;
				this.fetch(result);
				this.saveToLocalStorage();
				this.itemRequests[cacheId] = null;

				this.itemRetry = false;
				this.retryWatch = null;
				this.currentItemRetry = 0;
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + '.Item' + ', error: ' + exception.message);
				}
				this.currentItemRetry++;

				if (this.currentItemRetry < this.maxRetries) {
					this.retryWatch = setTimeout(() => {
						this.itemRequests[cacheId] = null;
						this.itemRetry = true;
						this.get(url, force, thenCallBack, catchCallBack);
					}, 1000);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
		return this.itemRequests[cacheId];
	}
	getLastRequest(cacheId) {
		if (typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null) {
			return this.lastRequest[cacheId];
		}
		return {};
	}
	length(cacheId) {
		// als de lijst er is dan lengte teruggeven
		if (typeof cacheId === 'undefined' || cacheId === null) {
			cacheId = 'list';
		}

		if (typeof this._list[cacheId] !== 'undefined' && this._list[cacheId] !== null) {
			return this._list[cacheId].length;
		} else if (this.listRequest === null) {
			// als er nog geen lijstverzoek loopt dan deze alsnog starten
			this.list(this.getLastRequest(cacheId).data, cacheId);
		}
		return 0;
	}
	save(data, urlExtension, thenCallBack, catchCallBack, objectName) {
		// voorlopig nog data meegeven. Later samenstellen
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		if (typeof objectName === 'undefined' || objectName === null) {
			objectName = this.objectName;
		}
		// variabelen op defaultwaarde zetten
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '/save';
		}
		let url = Data.baseUrl + this.url + urlExtension;

		this.saveRequest = this.store.dataAccess
			.post(url, data, null, null, null, objectName)
			.then((result) => {
				// resultaat ontvangen dan in de juiste velden plaatsen
				this.fetch(result);
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
				this.saveRequest = null;
			})
			.catch((exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
	}

	getCompaniesCount(urlExtension, thenCallBack, catchCallBack) {
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '/Count';
		}
		let url = Data.baseUrl + this.url + urlExtension;

		this.saveRequest = this.store.dataAccess
			.get(url, null, null, null)
			.then((result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
	}

	remove(data, urlExtension, thenCallBack, catchCallBack, objectName) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		if (typeof objectName === 'undefined' || objectName === null) {
			objectName = this.objectName;
		}
		// variabelen op defaultwaarde zetten
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '/DeleteConfiguration/' + data.calculationId;
		}
		let url = Data.baseUrl + this.url + urlExtension;
		this.saveRequest = data.store.dataAccess
			.post(url, data.calculationId, null, null, null, objectName)
			.then((result) => {
				this.fetch(result);
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})

			.catch((exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
	}

	resetCache(cacheId) {
		if (typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null) {
			let request = this.lastRequest[cacheId];
			this.list(
				request.data,
				true,
				request.cacheId,
				request.urlExtension,
				(result) => {
					if (typeof request.thenCallBack === 'function') {
						request.thenCallBack(result);
					}
				},
				(exception) => {
					if (this.logError === true) {
						Sentry.captureMessage('objectName: ' + this.objectName + ',data: ' + JSON.stringify(request.data) + ', error: ' + exception.message);
					}
					if (typeof request.catchCallBack === 'function') {
						request.catchCallBack(exception);
					}
				},
			);
		} else {
			for (let key in this.lastRequest) {
				let request = this.lastRequest[key];
				this.list(
					request.data,
					true,
					request.cacheId,
					request.urlExtension,
					(result) => {
						if (typeof request.thenCallBack === 'function') {
							request.thenCallBack(result);
						}
					},
					(exception) => {
						if (this.logError === true) {
							Sentry.captureMessage('objectName: ' + this.objectName + ',data: ' + JSON.stringify(request.data) + ', error: ' + exception.message);
						}
						if (typeof request.catchCallBack === 'function') {
							request.catchCallBack(exception);
						}
					},
				);
			}
		}
	}
}
