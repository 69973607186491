import { Mathematic } from '../helpers/mathematic';
import { Statics } from '../helpers/statics';
import { Data } from './data';
import { Store } from './store';

export class FloorVariableLoads extends Data {
	objectName = 'FloorVariableLoads';
	static CURRENT = null;
	url = 'FloorVariableLoads';
	getItem(variableLoad) {
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0];
		}
		return null;
	}
	getMaxCtC(variableLoad) {
		if (typeof variableLoad === 'undefined' || variableLoad === null) {
			return 0;
		}
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0].maxCtC;
		}
		return 0;
	}
	mappedList() {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			// Description aanpassen voor Imperial users.
			return this._list.list.map((vLoad) => {
				vLoad.caption = Store.CURRENT.currentConfiguration().isImperial() ? Mathematic.KgM2ToImperial(vLoad.value) + ' psf' : vLoad.caption;
				return vLoad;
			});
		} else {
			this.list(null, false, 'list', '', (res) => {
				return this.mappedList();
			});
		}
	}
}
